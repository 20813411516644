<template>
  <div class="short_memory">
    <div class="explain_box">
      这是来自《刻意练习》中的一个训练短期记忆的方法：首先从一个随机的、有5个数字的数字串开始，如果记住了，下轮记6个，如果记错了或没记住，下轮记4个……OK，点击下方【start】开始练习，goodluck!
    </div>
    <div class="guess_box">
      <div v-show="showCard" class="card_box">
        <template v-for="(item, index) in originArray">
          <transition name="fade" :key="index">
            <div class="number_card" v-if="index === showIndex">
              {{item}}
            </div>
          </transition>
        </template>
        <div v-show="showIndex == -1" class="guess">
          <div v-show="!showGuessResult">
            <div>
              请依次输入闪过的数字
              <div>
                目标字符数：{{startNumber}}个
              </div>
              已输字符数：{{inputNumber.length}}个
            </div>
            <textarea class="guess_input" type="text" v-model="inputNumber" />
            <div class="sure" @click="sure">确定</div>
          </div>
          <div v-show="showGuessResult" class="guess_result_box">
            <div>您输入的数字：
              <div>
                <template v-for="(item, index) in inputNumber.split('')">
                  <span :style="{color: item == originArray[index] ? 'black' : 'red'}" :key="index">{{item}}</span>
                </template>
              </div>
            </div>
            <div class="guess_sure" v-if="guessSure">
              猜对了哟，加油~继续挑战寄几
              <div>10个数字开始可上榜哦</div>
              <div>登录了才可上榜~</div>
            </div>
            <div class="guess_false" v-else>
              <div>啊哦，正确结果是</div>
              <div>
                <span class="sure_result">{{originArray.join('')}}</span>
              </div>加油~10个数字开始可上榜哦
            </div>
            <div class="again" @click="again">继续</div>
            <div v-if="userName" v-show="startNumber > 9 && showRankingBtn" class="to_ranking_list" @click="toRanking">上榜</div>
          </div>
        </div>
      </div>
      <div v-show='!showCard' class="operation_box">
        <input class="number_input" type="number" v-model="startNumber">
        个数字
        <div class="start" @click="start">start</div>
      </div>
      <div class="ranking_list_box">
        <div>排行榜</div>
        <div v-for="(item, index) in rankingList" :key="index" class="ranking_list">
          <div class="ranking_name">{{item.editor}}</div>
          <div class="ranking_line"></div>
          <div class="ranking_number">{{item.shortNumber}}个</div>
        </div>
      </div>
    </div>
    <div v-once class="footer_box">
      <div class="footer_box_font">那只是我职业生涯中一段最令人惊奇的旅程的开始。</div>
      <div class="footer_box_font">从那时起，史蒂夫开始缓慢而稳定地提高着记住数字串的能力。到第16次练习时，他能稳定地记住20个数字了，远远超出比尔和我对他能力的想象。</div>
      <div class="footer_box_font">练习了一百多次以后，他的记录达到了40个数字，比任何人都多，甚至专业研究记忆术的人，也达不到他的水平。而且，他还在不断进步。</div>
      <div class="footer_box_font">史蒂夫和我一同进行了二百次练习，所有这些练习结束时，他能记住82个数字了。82！思考片刻，你会意识到，这种记忆力到底有多么不可思议。以下是史蒂夫记住的82个随机的数字：</div>
      <div class="footer_box_font">0326443449602221328209301020391832373927788917267653245037746120179094345510355530</div>
      <div class="footer_box_font">想象一下，在听到别人向你以每秒一个数字的速度念出这82个数字，然后你能把它们全部记下来。这是一种什么样的情形！</div>
      <div class="footer_box_font">但是，在我们的实验持续两年左右的时间后，史蒂夫达到了这样的水平。他甚至不知道自己可以做到所有这些，只是一个星期接着一个星期地持续练习罢了。</div>
      <div class="footer_box_font">--《刻意练习》</div>
    </div>
  </div>
</template>

<script>
import { addShortRanking, allShortRanking } from '@/vue_api/short_ranking.js'
export default {
  name: 'shortMemory',
  data () {
    return {
      startNumber: 5,
      originArray: [],
      showNumber: true,
      inputNumber: '',
      sureMessage: '',
      showGuessResult: 0,
      guessSure: true,
      showCard: 0,
      showRankingBtn: 0,
      rankingList: [],
      userId: null,
      showIndex: 0
    }
  },
  mounted () {
    this.allRanking()
  },
  methods: {
    // 查榜
    allRanking () {
      this.rankingList = []
      allShortRanking().then(res => {
        console.log('---allRanking----', res)
        this.rankingList = res && res.data && res.data.rankingList ? res.data.rankingList : []
        this.rankingList.forEach(element => {
          this.userId = element.editor === this.userName ? element.id : null
        });
      })
    },
    // 上榜
    toRanking () {
      if (!this.userName) {
        console.log('请先登录---')
        return
      }
      if (this.startNumber < 10) {
        console.log('至少10个数字量才能上榜---')
        return
      }
      addShortRanking({
        id: this.userId,
        editor: this.userName,
        shortNumber: this.startNumber - 1
      }).then(res => {
        console.log('上榜结果------', res)
        this.allRanking()
      })
    },
    again () {
      this.showCard = 0
    },
    sure () {
      if (!this.inputNumber) {
        window.alert('请输入任意数字！！')
        return
      }
      this.$nextTick(() => {
        this.showGuessResult = 1
      })
      if (this.inputNumber && this.inputNumber.trim() == this.originArray.join('')) {
        console.log('你猜对了！！')
        this.guessSure = true
        this.showRankingBtn = this.startNumber > 9 ? 1 : 0
        this.startNumber++
      } else {
        this.showRankingBtn = 0
        this.guessSure = false
        if (this.startNumber > 1) {
          this.startNumber--
        }
      }
    },
    start () {
      // if(JSON.stringify(this.originArray) !== '[]') {return}
      this.originArray = []
      this.showCard = 1
      this.showGuessResult = 0
      for (let i = 0; i < this.startNumber; i++) {
        const a = Math.floor(Math.random() * 10)
        this.originArray.push(a.toString())
      }
      console.log(this.originArray, this.originArray.join(''))
      this.showIndex = -2
      let x = 0
      let timer = setInterval(() => {
        // this.showNumber = !this.showNumber
        this.showIndex = x++
        if (x + 1 > this.startNumber) {
          clearInterval(timer)
          setTimeout(() => {
            this.showIndex = -1
            this.inputNumber = ''
            this.showRankingBtn = 0
          }, 1000);
        }
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>