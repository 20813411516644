import baseApi from './base_api.js'

//  查询排行榜
export function allShortRanking(options) {
  return baseApi.post('/allShortRanking', {...options})
}

// 上榜
export function addShortRanking(options) {
  return baseApi.post('/addShortRanking', {...options})
}
